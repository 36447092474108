import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'google-map-react';

const GOOGLE_MAPS_KEY = process.env.REACT_GOOGLE_MAPS_API_KEY || window.REACT_GOOGLE_MAPS_API_KEY;

const googleMapsLink = (latitudeGeo, longitudeGeo) => (
  `http://maps.google.com/maps?q=&layer=c&cbll=${latitudeGeo},${longitudeGeo}&cbp=11,200,0,0,7`
);

const streetViewLink = (latitudeGeo, longitudeGeo) => (
  'https://maps.googleapis.com/maps/api/streetview?size=750x448&'
  + `location=${latitudeGeo},${longitudeGeo}&fov=100&heading=200&pitch=7&key=${GOOGLE_MAPS_KEY}`
);

function Marker({ match }) {
  return <div><img alt="" src={`/img/geo${!match ? '-partial' : ''}-match.png`} /></div>;
}

Marker.propTypes = {
  match: PropTypes.number,
};

class GeoLocation extends PureComponent {
  render() {
    const { matchGeo, latitudeGeo, longitudeGeo } = this.props;

    return (
      <div className="col-lg-12 ad-details-map">
        <h3 className="pull-left">
          Lokalizacja (
          {matchGeo ? 'dokładna' : 'przybliżona' }
          ):
        </h3>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 geo-location">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 google-map">
              <div id="map">
                <div id="mapContainer">
                  <GoogleMap id="map" defaultZoom={14}
                    bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY, libraries: ['drawing'] }}
                    defaultCenter={{ lat: latitudeGeo, lng: longitudeGeo }}
                  >
                    <Marker lat={latitudeGeo} lng={longitudeGeo} match={matchGeo} />
                  </GoogleMap>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12 pull-right google-street">
              <a target="_blank" rel="noopener noreferrer" title="Zobacz realny widok"
                href={googleMapsLink(latitudeGeo, longitudeGeo)}
              >
                <img alt="Lokalizacja na mapie" src={streetViewLink(latitudeGeo, longitudeGeo)} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GeoLocation.propTypes = {
  matchGeo: PropTypes.number.isRequired,
  latitudeGeo: PropTypes.number.isRequired,
  longitudeGeo: PropTypes.number.isRequired,
};

export default GeoLocation;
